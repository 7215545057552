@import "site-variables";
@import "variables";
@import "../node_modules/bootstrap/scss/bootstrap";
@import "bootswatch";

html,
body {
  height: 100%;
  overflow: auto;
}

main {
}

footer {
  text-align: center;
  margin: 18px;
}

.bg-gray {
  background-color: $gray-100;
}

.jumbotron {
  background-color: $gray-300;
}

.field-error {
  color: #e53935;
}

.login-form {
  text-align: center;
  max-width: 330px;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.login-form .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}

.login-form .form-control:focus {
  z-index: 2;
}

.login-form input[type="text"] {
  margin-bottom: -1px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.login-form input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.oops,
.sub-oops,
.help-oops,
.ghost {
  color: #606060;
}

.ghost-icon {
  height: 70%;
  width: 70%;
}

.ghost-icon path {
  fill: #606060;
}

.oops {
  font-size: 120px;
  font-weight: bold;
}

.loading-container {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
}

.loading-container.hidden {
  visibility: hidden;
  opacity: 0;
}

.loading-container.in-progress {
  visibility: visible;
  opacity: 1;
}

.loading,
.loading-notch {
  border: 3px solid #dfdfdf;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  display: inline-block;
  position: absolute;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.loading-container.in-progress .loading-notch {
  border-top: 3px solid #2196f3;
  -webkit-animation: rotate 1s infinite;
  -o-animation: rotate 1s infinite;
  animation: rotate 1s infinite;
}

.loading-container.success .loading-notch,
.loading-container.fail .loading-notch {
  border: none;
}

.loading-container .loading-result,
.loading-container .loading-result::before,
.loading-container .loading-result::after {
  transition: all 0.3s ease;
  width: 0;
  height: 0;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  right: 0;
  top: 0;
}

.loading-container.success .loading-result,
.loading-container.fail .loading-result {
  position: absolute;
  border-radius: 0;
  width: 6px;
  height: 10px;
  top: 6px;
  left: 5px;
  border-top: none;
  border-left: none;
  border-right: 2px solid green;
  border-bottom: 2px solid green;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transform-origin: 100% 100%;
  -moz-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  -o-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
  visibility: visible;
  opacity: 1;
}

.loading-container.success .loading {
  border-color: green;
}

.loading-container.fail .loading {
  border-color: red;
}

.loading-container.fail .loading-result {
  border: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
  transform: none;
  -webkit-transform-origin: 0;
  -moz-transform-origin: 0;
  -ms-transform-origin: 0;
  -o-transform-origin: 0;
  transform-origin: 0;
}

.loading-container.fail .loading-result::before,
.loading-container.fail .loading-result::after {
  position: absolute;
  content: "";
  width: 12px;
  height: 2px;
  background-color: red;
  left: 1px;
  right: 0;
  top: 5px;
  visibility: visible;
  opacity: 1;
}

.loading-container.fail .loading-result::before {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.loading-container.fail .loading-result::after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-o-keyframes rotate {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}

@-ms-keyframes rotate {
  0% {
    -ms-transform: rotate(0deg);
  }
  100% {
    -ms-transform: rotate(360deg);
  }
}

// Remove the standard select appearance...
// current version of bootstrap should include this...
// Don't know why it doesn't...

.form-select:disabled, .form-control:disabled, .form-check-input:disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, .38);
}

.form-check {
  margin-left: 0.25em;
}

.form-select-inline {
  display: inline-block;
  width: auto;
}

.form-control-inline {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.button-icon {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  fill: #aeaeae;
}

.button-icon-lg {
  width: 22px;
  height: 22px;
}

#page-loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background-color: #ffffff;
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

#page-loading.fade-out {
  visibility: hidden;
  opacity: 0;
  z-index: -1;
}

/* Dots */
.loading-dots {
  position: relative;
  text-align: center;
  top: 45%;
  height: 0;
}

.loading-dots .dot {
  display: inline-block;
  width: 32px;
  height: 32px;
  font-size: 12px;
  background-color: #2196f3;
  border-radius: 50%;
  margin: 0 1em;
  -webkit-animation: grow 0.75s ease-in-out infinite;
  -moz-animation: grow 0.75s ease-in-out infinite;
  -ms-animation: grow 0.75s ease-in-out infinite;
  -o-animation: grow 0.75s ease-in-out infinite;
  animation: grow 0.75s ease-in-out infinite;
}

.loading-dots .dot:nth-of-type(2) {
  -webkit-animation-delay: 0.25s;
  -moz-animation-delay: 0.25s;
  -ms-animation-delay: 0.25s;
  -o-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.loading-dots .dot:nth-of-type(3) {
  -webkit-animation-delay: 0.5s;
  -moz-animation-delay: 0.5s;
  -ms-animation-delay: 0.5s;
  -o-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes grow {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@-moz-keyframes grow {
  0% {
    -moz-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -moz-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@-ms-keyframes grow {
  0% {
    -ms-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -ms-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@-o-keyframes grow {
  0% {
    -o-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -o-transform: scale(1.5);
    transform: scale(1.5);
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

.md-text-field {
  position: relative;
  margin: 12px 4px 8px 0;
  display: inline-block;
}

.md-text-field .md-text-field__input {
  display: block;
  width: 100%;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  padding: 20px 0 1px;
  background-color: transparent;
  font-size: 1rem;
}

.md-text-field .md-floating-label {
  position: absolute;
  bottom: 6px;
  left: 0;
  margin: auto 0;
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
  line-height: 1.05rem;
  transform-origin: left top;
  -webkit-transition: transform 0.2s ease, color 0.2s ease;
  -moz-transition: transform 0.2s ease, color 0.2s ease;
  -ms-transition: transform 0.2s ease, color 0.2s ease;
  -o-transition: transform 0.2s ease, color 0.2s ease;
  transition: transform 0.2s ease, color 0.2s ease;
  cursor: text;
}

.md-text-field .md-line-ripple {
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  background-color: #2196f3;
  height: 2px;
  -webkit-transition: left 0.2s ease, right 0.2s ease;
  -moz-transition: left 0.2s ease, right 0.2s ease;
  -ms-transition: left 0.2s ease, right 0.2s ease;
  -o-transition: left 0.2s ease, right 0.2s ease;
  transition: left 0.2s ease, right 0.2s ease;
}

.md-text-field.md-text-field--focused .md-floating-label {
  color: #2196f3;
}

.md-text-field.md-text-field--focused .md-line-ripple {
  left: 0;
  right: 0;
}

.md-text-field .md-floating-label.md-floating-label--float-above {
  -webkit-transform: translateY(-100%) scale(0.75);
  -moz-transform: translateY(-100%) scale(0.75);
  -ms-transform: translateY(-100%) scale(0.75);
  -o-transform: translateY(-100%) scale(0.75);
  transform: translateY(-100%) scale(0.75);
}

.md-text-field:hover .md-text-field__input {
  border-bottom-color: rgba(0, 0, 0, 0.87);
}

.md-text-field.md-text-field--disabled {
}

.md-text-field.md-text-field--disabled .md-text-field__input {
  border-bottom: 1px dotted;
}

.md-text-field.md-text-field--disabled .md-floating-label {
  color: rgba(0, 0, 0, 0.37);
}

.md-text-field.md-text-field--invalid {
}

.md-text-field.md-text-field--invalid .md-text-field__input {
  border-bottom-color: #c51162;
}

.md-text-field.md-text-field--invalid .md-floating-label {
  color: #c51162;
}

.md-text-field.md-text-field--invalid .md-line-ripple {
  background-color: #c51162;
}

.md-text-field .md-text-field__input:invalid {
  box-shadow: none;
}

.md-text-field-helper-text {
  font-size: 0.75rem;
  margin: 0;
}

.md-text-field + .md-text-field-helper-text {
  margin-bottom: 8px;
}

.md-text-field.md-text-field--invalid + .md-text-field-helper-text {
  color: #c51162;
}

input:invalid,
select:invalid {
  box-shadow: rgb(255, 0, 0) 0 0 1.5px 1px;
}

input.invalid {
  box-shadow: rgb(255, 0, 0) 0 0 1.5px 1px;
}

.loading-text {
  border-radius: 8px;
  height: 1em;
  line-height: 1em;
  background-color: #e0e0e0;
  display: inline-block;
  vertical-align: middle;
}

.loading-text.ten {
  width: 100%;
}

.loading-text.nine {
  width: 90%;
}

.loading-text.eight {
  width: 80%;
}

.loading-text.seven {
  width: 70%;
}

.loading-text.six {
  width: 60%;
}

.loading-text.five {
  width: 50%;
}

.loading-text.four {
  width: 40%;
}

.loading-text.three {
  width: 30%;
}

.loading-text.two {
  width: 20%;
}

.loading-text.one {
  width: 10%;
}

.tooltip-message-container {
  position: relative;
}

.tooltip-message-container:hover .tooltip-message {
  display: inline;
}

.tooltip-message {
  display: none;
  background-color: #404040;
  color: #ffffff;
  padding: 8px 16px;
  border-radius: 4px;
}

.dropdown-menu {
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.dropdown-menu-inner {
  overflow-x: auto;
  max-height: 50vh;
}

// Navbar tweaks
.bg-primary {
  .navbar-nav .active > .nav-link {
    color: $yellow !important;
  }
}

.bg-dark {
  background-color: $blue !important;
  &.navbar-dark .navbar-nav {
    .nav-link:focus,
    .nav-link:hover,
    .active > .nav-link {
      color: $yellow !important;
    }
  }
}

// Button Labels

.btn-label {
  position: relative;
  left: -12px;
  display: inline-block;
  padding: 6px 12px;
}

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0;
}

.text-muted {
  color: #515a5b !important;
}

.bd-callout {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: 0.25rem;
  border-radius: 0.25rem;
  h2,
  h3,
  h4,
  h5 {
    margin-top: 0;
    margin-bottom: 0.25rem;
  }
  p:last-child {
    margin-bottom: 0;
  }
  code {
    border-radius: 0.25rem;
  }
}

.bd-callout-primary {
  border-left-color: $primary;
  h2,
  h3,
  h4,
  h5 {
    color: $primary;
  }
}

.bd-callout-secondary {
  border-left-color: $secondary;
  h2,
  h3,
  h4,
  h5 {
    color: $secondary;
  }
}
.bd-callout-success {
  border-left-color: $success;
  h2,
  h3,
  h4,
  h5 {
    color: $success;
  }
}
.bd-callout-info {
  border-left-color: $info;
  h2,
  h3,
  h4,
  h5 {
    color: $info;
  }
}
.bd-callout-warning {
  border-left-color: $warning;
  h2,
  h3,
  h4,
  h5 {
    color: $warning;
  }
}
.bd-callout-danger {
  border-left-color: $danger;
  h2,
  h3,
  h4,
  h5 {
    color: $danger;
  }
}
.bd-callout-light {
  border-left-color: $light;
  h2,
  h3,
  h4,
  h5 {
    color: $light;
  }
}
.bd-callout-dark {
  border-left-color: $dark;
  h2,
  h3,
  h4,
  h5 {
    color: $dark;
  }
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

// Home //////////
.homeBody {
  padding: 2em;
  margin: 1em;
}

.homeCard {
  background-color: white;
}

.homeCard:hover {
  color: black;
  background-color: #e8f4f8;
}

.expiredCard {
  background-color: lightyellow;
}

.homeCardLink {
  text-decoration: none;
  color: black;
}

.homeCardLink:hover {
  color: black;
}

// Support

.supportCard {
  padding: 1.2em 1.2em 0.2em !important;
  margin: 0.5em !important;
}

.pdfColor {
  background-color: white;
}

.exeColor {
  background-color: #daf1da;
}

.zipColor {
  background-color: #d9e8fd;
}

.htmlCard {
  background-color: #fadca9;
}

// Common Styles /////////////
.headerText {
  color: black;
  font-size: 2em;
  font-weight: bold;
}

.subHeaderText {
  font-size: 1.4em;
  font-weight: bold;
}

.subHeaderTextAlt {
  font-size: 1.4em;
}

.detailsText {
  color: gray;
  font-style: italic;
}

.docSectionRow {
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 0.5em;
  border: solid 1px gray;
  margin-top: 1em;
  cursor: pointer;
  border-radius: 0.1em;
  box-shadow: 0 4px 12px #0000001a;

  transition: all 0.3s ease-in-out;
}

.docSectionRow:hover {
  box-shadow: 0 0 2px 4px rgba(0,0,0,0.2);
}

.basicCard {
  padding: 1em;
  margin: 1em;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.blinkAnim {
  transform: translateZ(0) scale(1);
  transition: transform .25s;

  animation-name: fadeIn;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.25s;
}

.blinkAnim:hover {
  transform: scale(1.05);
  z-index: 100;
}

.whiteBg {
  background-color: white;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.flexSpaceEvenly {
  display: flex;
  justify-content: space-evenly;
}

.flexSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.vertMargin {
  margin-top: 1em;
  margin-bottom: 1em;
}

.list-page-search-div {
  margin-left: 1em;
  margin-bottom: 1em;
  font-weight: bold;
  white-space: nowrap;
}

.show-more-hidden, .show-more-hidden table, .show-more-hidden tr, .show-more-hidden td {
  display: none;
}

.show-more-displayed {
  display: revert;
}

.click-row-content {
  padding: 0.5em;
  background-color: white;
  transition: all 0.4s ease-in-out;
}

.content-table {
  margin-left: auto;
  margin-right: auto;
  padding: 0.5em;
  width: 95%;
}

.content-table tbody tr td, .content-table thead tr td {
  padding: 0.5em;
}

.content-table tbody tr {
  border: solid 1px black;
  border-radius: 0.2em;
}

.content-table thead tr {
  border: solid 1px black;
  border-radius: 0.2em;
}

.content-table tbody tr:nth-child(odd) {
  background-color: whitesmoke;
}

.content-table-header {
  font-weight: bold;
  text-decoration: underline;
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.hidden-area {
  background-color: red;
}

.navbar-logo-img {
  width: 100%;
  max-width: 5em;
}

.navbar-text {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.25em;
}

.navbar-inner {
  display: flex;
}

.navbar-collapse {
  transition: all 0.4s ease-in-out;
}

.mobile-form-layout {
  display: none;
}

.web-form-layout {
  display: block;
}

.flex-display {
  display: flex;
  justify-content: space-between;
}

.doc-section-row-item-title {
  font-weight: bold;
  text-decoration: underline;
}

.doc-section-row-item-type {
  font-size: 0.8em;
  font-style: italic;
  float: right;
}

// Most phones
@media (max-width: 480px) {
  .headerText {
    color: black;
    font-size: 1.5em;
    font-weight: bold;
  }

  .subHeaderText {
    font-size: 1.2em;
    font-weight: bold;
    margin-top: 0.25em;
  }

  .subHeaderTextAlt {
    font-size: 1.2em;
    margin-top: 0.25em;
  }

  .homeBody {
    padding: 1em;
    margin: 0.25em;
  }

  .basicCard {
    padding: 1em;
    margin: 0.5em;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .navbar-text {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0.25em;
    font-size: 0.8em;
  }

  .navbar-logo-img {
    width: auto;
    height: 1.5em;
    margin-top: auto;
    margin-bottom: auto;
  }

  .navbar-nav-scroll {
    width: 100%;
  }

  .mobile-form-layout {
    display: block;
  }

  .web-form-layout {
    display: none;
  }

  .docSectionRow {
    margin-left: 0.5em;
    margin-right: 0.5em;
    border: solid 1px gray;
    cursor: pointer;
    border-radius: 0.1em;
    box-shadow: 0 4px 12px #0000001a;
    padding: 0.5em;
    transition: all 0.3s ease-in-out;
  }

  .doc-section-row-inner {
    display: flex;
    flex-direction: column;
  }

  .doc-section-row-item {
    margin-bottom: 0.25em;
  }
}

.route-inspector-main {
  background-color: #153d93;
  color: white;
}

.route-inspector-main:hover {
  background-color: #4c6fe5;
  color: white;
}

.link-card-icon {
  height: 4em;
  padding-right: 0.5em;
}

.link-card-body {
  margin-top: 0.5em;
}